<template>
  <div class="bg">

    <!--    Popup for Challenge-->
    <md-dialog
        :md-active.sync="showChallenge"
        v-if="!myWidth"
        v-on:md-clicked-outside="
        (showChallenge = false), (selectedChallenge = 0), (selectedDay = 999)
      "
    >
      <md-dialog-content style="margin: 20px; overflow: auto" v-if="content[selectedChallenge]">
        <div class="challengeHeader">
          <div class="challengeTitle" v-if="selectedDay == 999">
            {{ content[selectedChallenge].title }}
          </div>
          <div class="challengeTitle" v-else>
            Day {{ selectedDay + 1 }} -
            {{ content[selectedChallenge].activity[selectedDay].title }}
          </div>
          <button
              class="challengeButton"
              v-if="!this.user"
              @click="link({ name: 'Signin' })"
          >
            <md-icon>login</md-icon>
            Login to Start
          </button>
          <button
              class="challengeButton"
              v-else-if="
              this.user.challenges
                .map((challenge) => challenge.challenge ? challenge.challenge._id : '')
                .includes(content[selectedChallenge]._id)
            "
              @click="removeChallenge()"
          >
            <md-icon>bookmark</md-icon>
            Challenge Started
          </button>
          <button class="challengeButton" v-else @click="addChallenge()">
            <md-icon>bookmark_border</md-icon>
            Start Challenge
          </button>
        </div>
        <div class="challengeImage" @click="selectedDay = 999">
          {{ content[selectedChallenge].title }}
        </div>
        <div class="flexRow">
          <div class="challengeWrapper">
            <div>
              <div
                  v-for="(activity, i) in content[selectedChallenge].activity"
                  v-bind:key="i"
                  :class="
                  selectedDay == i
                    ? 'challengeActivitySelected'
                    : 'challengeActivity'
                "
                  @click="selectedDay = i"
              >
                <md-icon style="margin: 0; margin-right: 5px"
                >check_box_outline_blank
                </md-icon
                >
                Day {{ i + 1 }} - {{ activity.title }}
              </div>
            </div>
            <div class="author" v-if="content[selectedChallenge].author">
              <div class="flexRow">
                <img
                    :src="content[selectedChallenge].author.img"
                    v-if="content[selectedChallenge].author.img"
                />
                <div class="authorName">
                  Author:<br/>{{ content[selectedChallenge].author.name }}
                </div>
              </div>
              <div
                  class="academicDescription"
                  v-if="content[selectedChallenge].author.academicDescription"
              >
                {{ content[selectedChallenge].author.academicDescription }}
              </div>
            </div>
          </div>
          <div class="challengeText" v-if="selectedDay == 999">
            <vue-markdown>{{ content[selectedChallenge].text }}</vue-markdown>
          </div>
          <div class="challengeText" v-else>
            <div
                v-bind:key="i"
                v-for="(a, i) in content[selectedChallenge].activity"
            >
              <vue-markdown v-if="i === selectedDay">{{
                  content[selectedChallenge].activity[selectedDay].text
                }}
              </vue-markdown>
            </div>
          </div>
        </div>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button
            class="md-primary"
            @click="
            (showChallenge = false),
              (selectedChallenge = 0),
              (selectedDay = 999)
          "
            style="font-size: 1.3em; margin: 0 20px 20px 0"
        >Close
        </md-button
        >
      </md-dialog-actions>
    </md-dialog>


    <md-dialog
        :md-active.sync="showChallenge"
        v-else
        v-on:md-clicked-outside="
        (showChallenge = false), (selectedChallenge = 0), (selectedDay = 999)
      "
    >
      <md-dialog-content style="margin: 20px; overflow: auto" v-if="content[selectedChallenge]">
        <div class="challengeHeaderMobile">
          <div class="challengeTitle" v-if="selectedDay == 999">
            {{ content[selectedChallenge].title }}
          </div>
          <div class="challengeTitle" v-else>
            Day {{ selectedDay + 1 }} -
            {{ content[selectedChallenge].activity[selectedDay].title }}
          </div>
          <button
              class="challengeButton"
              v-if="!this.user"
              @click="link({ name: 'Signin' })"
          >
            <md-icon>login</md-icon>
            Login to Start
          </button>
          <button
              class="challengeButton"
              v-else-if="
              this.user.challenges
                .map((challenge) => challenge.challenge ? challenge.challenge._id : '')
                .includes(content[selectedChallenge]._id)
            "
              @click="removeChallenge()"
          >
            <md-icon>bookmark</md-icon>
            Challenge Started
          </button>
          <button class="challengeButton" v-else @click="addChallenge()">
            <md-icon>bookmark_border</md-icon>
            Start Challenge
          </button>
        </div>
        <div class="challengeImage" @click="selectedDay = 999">
          {{ content[selectedChallenge].title }}
        </div>
        <div class="flexColumn">
          <div class="challengeWrapperMobile">
            <div
                v-for="(activity, i) in content[selectedChallenge].activity"
                v-bind:key="i"
                :class="
                selectedDay == i
                  ? 'challengeActivitySelectedMobile'
                  : 'challengeActivityMobile'
              "
                @click="selectedDay = i"
            >
              <md-icon style="margin: 0; margin-right: 5px"
              >check_box_outline_blank
              </md-icon
              >
              Day {{ i + 1 }} - {{ activity.title }}
            </div>
          </div>
          <div class="challengeTextMobile" v-if="selectedDay == 999">
            <vue-markdown>{{ content[selectedChallenge].text }}</vue-markdown>
          </div>
          <div class="challengeTextMobile" v-else>
            <div
                v-bind:key="i"
                v-for="(a, i) in content[selectedChallenge].activity"
            >
              <vue-markdown v-if="i === selectedDay">{{
                  content[selectedChallenge].activity[selectedDay].text
                }}
              </vue-markdown>
            </div>
          </div>
          <div class="author" v-if="content[selectedChallenge].author">
            <div class="flexRow">
              <img
                  :src="content[selectedChallenge].author.img"
                  v-if="content[selectedChallenge].author.img"
              />
              <div class="authorName">
                Author:<br/>{{ content[selectedChallenge].author.name }}
              </div>
            </div>
            <div
                class="academicDescription"
                v-if="content[selectedChallenge].author.academicDescription"
            >
              {{ content[selectedChallenge].author.academicDescription }}
            </div>
          </div>
        </div>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button
            class="md-primary"
            @click="
            (showChallenge = false),
              (selectedChallenge = 0),
              (selectedDay = 999)
          "
            style="font-size: 1.3em; margin: 0 20px 20px 0"
        >Close
        </md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <!--    Popup Challenge end-->


    <div class="wrapper">
      <!-- Header with imager -->
      <h1 v-if="['practice', 'knowledge', 'challenge'].includes(materialActive)">{{ materialActive }}</h1>
      <h1 v-else>Knowledge & Practice</h1>
      <hr/>
      <div class="tagBar flexRow">
        <!-- Material bar start -->
        <p
            v-for="material in materials"
            v-bind:key="material"
            v-bind:id="material"
            @click="
              material==='All' ?
              filterMaterials('viewall') :
              filterMaterials(material)
            "
            :class="
              materialActive === 'viewall' && material === 'All' ?
              'tagActive' :
              (
                materialActive === material.toLowerCase() ?
                'tagActive' :
                ''
               )
            "
        >
          {{ material }}
        </p>
      </div>
      <!-- Material bar end -->
      <hr/>

      <!-- Tag bar start -->
      <div class="tagBar flexRow">
        <p
            v-for="tag in tags"
            v-bind:key="tag"
            v-bind:id="tag"
            @click="filterTags(tag)"
            :class="tagActive === tag ? 'tagActive' : ''"
        >
          {{ tag }}
        </p>

        <!-- Search Applied from: https://codepen.io/AndrewThian/pen/QdeOVa -->
        <input
            type="text"
            v-model="search"
            placeholder="Search article or author.."
            class="searchInput"
        />
      </div>
      <!-- Tag bar end -->

      <hr/>
      <!-- Article list start -->
      <div class="articles" id="articles">
        <div
            v-for="(article, i) in filteredArticles"
            v-bind:key="article"
            :id="i"
            v-bind:class="{ articleMobile: maxWidth, article: !maxWidth }"
        >
          <div
              @click="$router.push('../../article/' + article._id)"
              v-if="article.content"
          >
            <div class="articleImageDiv">
              <img class="articleImage" :src="article.image"/>
            </div>
            <p class="articleTitle">{{ article.title }}</p>
            <p class="articleSubtitle">{{ article.subheader }}</p>
            <p class="articleAuthor" v-if="article.author">{{ article.author.name }}</p>
            <p class="articleStars">Difficulty -
              <span
                  v-for="i in article.stars"
                  v-bind:key="i"
                  :class="'stars' + article.stars"
              >⭐</span
              >
            </p></div>
          <div
              @click="
              (selectedChallenge = i),
                (showChallenge = true),
                (selectedDay = 999)
            "
              v-else
          >
            <div class="articleImageDiv">
              <img class="articleImage" :src="article.image"/>
            </div>
            <p class="articleTitle">{{ article.title }}</p>
            <p class="articleSubtitle" v-if="article.text">
              {{ article.text.split(" ").slice(0, 20).join(" ") + "..." }}
            </p>
            <p class="articleAuthor" v-if="article.author">{{ article.author.name }}</p>
          </div>
        </div>
      </div>
      <!-- Article list end -->
    </div>
  </div>
</template>

<script>
import store from "@/store";
import VueMarkdown from "@adapttive/vue-markdown";
import {axiosGet, axiosPost} from "@/utils/axiosWrapper";

export default {
  data() {
    return {
      kind: this.$router.currentRoute.params.kind,

      maxWidth: window.innerWidth < 1280,
      myWidth: screen.height / screen.width > 1,

      tagActive: "All",
      materialActive: this.$router.currentRoute.params.kind,
      selectedChallenge: 0,
      selectedDay: 999,
      showChallenge: false,
      search: "",

      tags: [],
      materials: [],
      content: [],
      contentRaw: [],
      challenges: [],
      videos: [],
      articles: [],

      user: null,
      userId: store.state.accessToken,
    };
  },
  // Function to filter articles by title; requires v-for in HTML to be set to this name
  computed: {
    filteredArticles() {
      return this.content.filter((article) => {
        return (
            article.title.toLowerCase().includes(this.search.toLowerCase()) ||
            (
                article.author &&
                article.author.name &&
                article.author.name.toLowerCase().includes(this.search.toLowerCase())
            )
        );
      });
    },
  },
  methods: {
    sortArticles: function () {
      this.content = this.content.sort((a, b) => new Date(b.created) - new Date(a.created))
    },
    filterTags: function (key) {
      if (this.tagActive !== "All")
        this.filterMaterials(this.materialActive)
      this.tagActive = key;
      // FILTERING CHALLENGES/PRACTICE BY TAGS DOESN'T WORK -> Reset Material to Knowledge
      if (key === "All") {
        this.filterMaterials(this.materialActive)
        return;
      }
      this.content = this.content.filter((a) => a.tags && a.tags.includes(key));
    },
    computeTags: function (object) {
      this.tags = new Set([
        "All",
        ...object
            .map((c) => c.tags)
            .reduce((a, b) => a.concat(b), [])
            .sort(),
      ]);
    },
    filterMaterials: function (key) {
      this.materialActive = key.toLowerCase();
      // FILTERING CHALLENGES/PRACTICE BY TAGS DOESN'T WORK -> Reset Tags to All

      switch (this.materialActive) {
        case "challenge":
          this.content = this.challenges;
          break;
        case "practice":
          this.content = [...this.videos, ...this.challenges];
          break;
        case "knowledge":
          this.content = this.articles
          break;
        default:
          this.content = [...this.contentRaw, ...this.challenges];
      }

      // compute available tags
      this.computeTags(this.content);

      // Sort Stuff
      this.sortArticles()
    },
    determineMobile() {
      this.maxWidth = window.innerWidth < 1280;
    },
    loadData() {
      return axiosGet("/users/id/" + this.userId)
          .then(function (response) {
            return response.data;
          })
          .catch(function (error) {
            console.warn(error);
          });
    },
    link(to) {
      this.$router
          .push(to)
          .catch((err) => console.warn(`Seems we are already here: ${err}`));
    },
    addChallenge() {
      axiosPost("/users/challenge/save", {
        content: this.content[this.selectedChallenge],
        userId: this.userId,
      });
      this.link({name: 'Dashboard'});
    },
    async removeChallenge() {
      const c = this.user.challenges.find(x => x.challenge._id === this.content[this.selectedChallenge]._id);
      await axiosPost("/users/challenge/remove", {
        content: c,
        userId: this.userId,
      });
      this.user = await this.loadData();
    }
  },
  async mounted() {
    // Content
    this.contentRaw = (
        await axiosGet(
            "/selfSupport/content/published"
        )
    ).data;
    this.videos = this.contentRaw.filter(a => a.material === 'Video')
    this.articles = this.contentRaw.filter(a => a.material === 'Article')

    // Challenges
    this.challenges = (
        await axiosGet(
            "/selfSupport/challenge/published"
        )
    ).data;

    // reset Tags
    this.filterTags("All");

    // compute available materials
    this.materials = new Set([
      "All", "Challenge", "Practice", "Knowledge"
    ]);

    // compute available tags
    this.computeTags(this.contentRaw)

    // get user
    if (this.userId)
      this.user = await this.loadData();
  },
  async created() {
    window.addEventListener("resize", this.determineMobile);
  },
  destroyed() {
    window.removeEventListener("resize", this.determineMobile);
  },
  components: {
    VueMarkdown,
  },
};
</script>

<style scoped>
.bg {
  background-color: white;
}

.wrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
}

h1 {
  font-size: 6em;
  text-shadow: 0px 2px 2px #666666;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  background-image: url(../assets/selfSupport/practice_banner_new.png);
  background-size: 100% 100%;
  padding-top: calc(var(--width) * (640 / 2596) / 2);
  --width: 1280px;
  width: var(--width);
  height: calc(var(--width) * (640 / 2596));
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.tagBar {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
}

.tagBar p {
  padding: 20px;
  margin: 0;
  text-transform: capitalize;
}

.tagBar p:hover {
  color: #1a9688;
  cursor: pointer;
  font-weight: bold;
}

.tagActive {
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: #4e84ad;
}

input {
  padding: 4px 12px;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: 0.15s all ease-in-out;
  background: white;
  margin: 0;
  margin-right: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: right;
}

input:focus {
  outline: 1px solid #1a9688;
  /*transform: scale(1.05);*/
}

input:focus::placeholder {
  color: transparent;
  transition: 0.2s ease-in-out;
}

.searchInput {
  margin-left: auto;
  /*flex: 1;*/
  height: 60px;
}

.articles {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
}

.article {
  width: 33%;
  padding: 3%;
  margin: 0;
  box-sizing: border-box;

  transition: all 0.2s ease-in-out;

  text-align: left;
}

.articleMobile {
  width: 50%;
  padding: 3%;
  margin: 0;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  text-align: left;
}

.article:hover,
.articleMobile:hover {
  opacity: 0.8;
  transform: scale(0.98);
  cursor: pointer;
}

/* placeholder div for missing images */
.articleImageDiv {
  background-color: #ccc;
  width: 100%;
  min-height: 230px;
}

.articleMobile .articleImageDiv {
  min-height: 100px;
}

.articleTitle {
  font-size: 1em;
  font-weight: bold;
}

.articleSubtitle {
  text-align: left;
}

.articleAuthor {
  letter-spacing: -0.33px;
  font-size: 0.9em;
}

.challengeHeader,
.challengeHeaderMobile {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
}

.challengeHeaderMobile {
  flex-direction: column;
}

.challengeButton,
.challengeButtonRestart,
.challengeButtonCompleted {
  background: #ffffff;
  border: 3px #00000020 solid;
  border-radius: 10px 10px 10px 10px;
  font-size: 20px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  margin: 20px 20px 0 0;
}

.challengeButton:hover,
.challengeButtonRestart:hover {
  transform: scale(1.1);
  border: 3px #00000070 solid;
}

.challengeTitle {
  font-size: 40px;
  line-height: 40px;
  color: var(--c3);
  text-transform: uppercase;
  font-family: var(--articleh1);
}

.challengeWrapper,
.challengeWrapperMobile {
  width: 30%;
  padding-top: 20px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.challengeWrapperMobile {
  width: 100%;
}

.challengeActivity,
.challengeActivitySelected,
.challengeActivityMobile,
.challengeActivitySelectedMobile {
  display: flex;
  flex-direction: row;
  padding-left: 20%;
  padding-bottom: 20px;
  color: var(--c4);
  font-size: 1.5em;
  line-height: 1.5em;
  opacity: 0.5;
  cursor: pointer;
  align-items: center;
  font-family: var(--articleText);
}

.challengeActivityMobile,
.challengeActivitySelectedMobile {
  padding-left: 5%;
}

.challengeActivitySelectedMobile {
  opacity: 0.9;
}

.challengeActivitySelected {
  opacity: 0.9;
  /* font-weight: bold; */
}

.challengeText,
.challengeTextMobile {
  text-align: justify;
  width: 70%;
  padding: 20px 50px 20px 50px;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-family: var(--articleText);
}

.challengeTextMobile {
  width: 100%;
  padding: 20px 0 20px 0;
}

.challengeImage {
  height: 120px;
  width: 100%;
  background-size: 100% 100%;
  font-size: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: var(--articleh1);
  background-image: url("../assets/ChallengesBanner.png");
}

.author {
  margin-top: 20px;
  font-size: 1.5em;
  text-align: left;
}

.author img {
  box-sizing: border-box;
  width: 50%;
  margin: 10px;
  border-radius: 50%;
  border: 1px solid gray;
  box-shadow: 0px 0px 10px #ccc;
}

.authorName {
  width: 50%;
  margin: 0 auto;
  margin-top: 10%;
  padding-top: 10%;
  padding-bottom: 10%;
  text-align: center;
  box-sizing: border-box;
  border-left: 1px dashed #ccc;
  margin-left: 15px;
  height: 50%;
}

.author .academicDescription {
  font-size: 0.75em;
  padding: 5px;
  background: #f9f9f9;
  border-left: 5px solid #ccc;
}

.articleStars {
  color: grey;
  font-style: italic;
  text-align: right;
}

.stars1 {
  color: transparent;
  text-shadow: 0 0 0 green;
}

.stars2 {
  color: transparent;
  text-shadow: 0 0 0 yellow;
}

.stars3 {
  color: transparent;
  text-shadow: 0 0 0 red;
}

@media only screen and (max-width: 640px) {
  h1 {
    font-size: 4em;
    padding-top: calc(var(--width) * (1024 / 2596) / 2);
    --width: 100vw;
    width: var(--width);
    height: calc(var(--width) * (1024 / 2596));
  }

  .tagBar p {
    padding: 10px;
  }

  .challengeImage {
    font-size: 2em;
    line-height: 1.2;
  }

  .searchInput {
    height: 40px;
    flex: 1;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1280px) {
  h1 {
    font-size: 4em;
    padding-top: calc(var(--width) * (315 / 1280) / 2);
    --width: 100vw;
    width: calc(100vw - (100vw - 100%));
    height: calc(var(--width) * (315 / 1280));
  }

  .searchInput {
    margin-left: auto;
    margin-right: 10px;
  }
}
</style>