var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg"},[(!_vm.myWidth)?_c('md-dialog',{attrs:{"md-active":_vm.showChallenge},on:{"update:mdActive":function($event){_vm.showChallenge=$event},"update:md-active":function($event){_vm.showChallenge=$event},"md-clicked-outside":function($event){(_vm.showChallenge = false), (_vm.selectedChallenge = 0), (_vm.selectedDay = 999)}}},[(_vm.content[_vm.selectedChallenge])?_c('md-dialog-content',{staticStyle:{"margin":"20px","overflow":"auto"}},[_c('div',{staticClass:"challengeHeader"},[(_vm.selectedDay == 999)?_c('div',{staticClass:"challengeTitle"},[_vm._v(" "+_vm._s(_vm.content[_vm.selectedChallenge].title)+" ")]):_c('div',{staticClass:"challengeTitle"},[_vm._v(" Day "+_vm._s(_vm.selectedDay + 1)+" - "+_vm._s(_vm.content[_vm.selectedChallenge].activity[_vm.selectedDay].title)+" ")]),(!this.user)?_c('button',{staticClass:"challengeButton",on:{"click":function($event){return _vm.link({ name: 'Signin' })}}},[_c('md-icon',[_vm._v("login")]),_vm._v(" Login to Start ")],1):(
            this.user.challenges
              .map(function (challenge) { return challenge.challenge ? challenge.challenge._id : ''; })
              .includes(_vm.content[_vm.selectedChallenge]._id)
          )?_c('button',{staticClass:"challengeButton",on:{"click":function($event){return _vm.removeChallenge()}}},[_c('md-icon',[_vm._v("bookmark")]),_vm._v(" Challenge Started ")],1):_c('button',{staticClass:"challengeButton",on:{"click":function($event){return _vm.addChallenge()}}},[_c('md-icon',[_vm._v("bookmark_border")]),_vm._v(" Start Challenge ")],1)]),_c('div',{staticClass:"challengeImage",on:{"click":function($event){_vm.selectedDay = 999}}},[_vm._v(" "+_vm._s(_vm.content[_vm.selectedChallenge].title)+" ")]),_c('div',{staticClass:"flexRow"},[_c('div',{staticClass:"challengeWrapper"},[_c('div',_vm._l((_vm.content[_vm.selectedChallenge].activity),function(activity,i){return _c('div',{key:i,class:_vm.selectedDay == i
                  ? 'challengeActivitySelected'
                  : 'challengeActivity',on:{"click":function($event){_vm.selectedDay = i}}},[_c('md-icon',{staticStyle:{"margin":"0","margin-right":"5px"}},[_vm._v("check_box_outline_blank ")]),_vm._v(" Day "+_vm._s(i + 1)+" - "+_vm._s(activity.title)+" ")],1)}),0),(_vm.content[_vm.selectedChallenge].author)?_c('div',{staticClass:"author"},[_c('div',{staticClass:"flexRow"},[(_vm.content[_vm.selectedChallenge].author.img)?_c('img',{attrs:{"src":_vm.content[_vm.selectedChallenge].author.img}}):_vm._e(),_c('div',{staticClass:"authorName"},[_vm._v(" Author:"),_c('br'),_vm._v(_vm._s(_vm.content[_vm.selectedChallenge].author.name)+" ")])]),(_vm.content[_vm.selectedChallenge].author.academicDescription)?_c('div',{staticClass:"academicDescription"},[_vm._v(" "+_vm._s(_vm.content[_vm.selectedChallenge].author.academicDescription)+" ")]):_vm._e()]):_vm._e()]),(_vm.selectedDay == 999)?_c('div',{staticClass:"challengeText"},[_c('vue-markdown',[_vm._v(_vm._s(_vm.content[_vm.selectedChallenge].text))])],1):_c('div',{staticClass:"challengeText"},_vm._l((_vm.content[_vm.selectedChallenge].activity),function(a,i){return _c('div',{key:i},[(i === _vm.selectedDay)?_c('vue-markdown',[_vm._v(_vm._s(_vm.content[_vm.selectedChallenge].activity[_vm.selectedDay].text)+" ")]):_vm._e()],1)}),0)])]):_vm._e(),_c('md-dialog-actions',[_c('md-button',{staticClass:"md-primary",staticStyle:{"font-size":"1.3em","margin":"0 20px 20px 0"},on:{"click":function($event){(_vm.showChallenge = false),
            (_vm.selectedChallenge = 0),
            (_vm.selectedDay = 999)}}},[_vm._v("Close ")])],1)],1):_c('md-dialog',{attrs:{"md-active":_vm.showChallenge},on:{"update:mdActive":function($event){_vm.showChallenge=$event},"update:md-active":function($event){_vm.showChallenge=$event},"md-clicked-outside":function($event){(_vm.showChallenge = false), (_vm.selectedChallenge = 0), (_vm.selectedDay = 999)}}},[(_vm.content[_vm.selectedChallenge])?_c('md-dialog-content',{staticStyle:{"margin":"20px","overflow":"auto"}},[_c('div',{staticClass:"challengeHeaderMobile"},[(_vm.selectedDay == 999)?_c('div',{staticClass:"challengeTitle"},[_vm._v(" "+_vm._s(_vm.content[_vm.selectedChallenge].title)+" ")]):_c('div',{staticClass:"challengeTitle"},[_vm._v(" Day "+_vm._s(_vm.selectedDay + 1)+" - "+_vm._s(_vm.content[_vm.selectedChallenge].activity[_vm.selectedDay].title)+" ")]),(!this.user)?_c('button',{staticClass:"challengeButton",on:{"click":function($event){return _vm.link({ name: 'Signin' })}}},[_c('md-icon',[_vm._v("login")]),_vm._v(" Login to Start ")],1):(
            this.user.challenges
              .map(function (challenge) { return challenge.challenge ? challenge.challenge._id : ''; })
              .includes(_vm.content[_vm.selectedChallenge]._id)
          )?_c('button',{staticClass:"challengeButton",on:{"click":function($event){return _vm.removeChallenge()}}},[_c('md-icon',[_vm._v("bookmark")]),_vm._v(" Challenge Started ")],1):_c('button',{staticClass:"challengeButton",on:{"click":function($event){return _vm.addChallenge()}}},[_c('md-icon',[_vm._v("bookmark_border")]),_vm._v(" Start Challenge ")],1)]),_c('div',{staticClass:"challengeImage",on:{"click":function($event){_vm.selectedDay = 999}}},[_vm._v(" "+_vm._s(_vm.content[_vm.selectedChallenge].title)+" ")]),_c('div',{staticClass:"flexColumn"},[_c('div',{staticClass:"challengeWrapperMobile"},_vm._l((_vm.content[_vm.selectedChallenge].activity),function(activity,i){return _c('div',{key:i,class:_vm.selectedDay == i
                ? 'challengeActivitySelectedMobile'
                : 'challengeActivityMobile',on:{"click":function($event){_vm.selectedDay = i}}},[_c('md-icon',{staticStyle:{"margin":"0","margin-right":"5px"}},[_vm._v("check_box_outline_blank ")]),_vm._v(" Day "+_vm._s(i + 1)+" - "+_vm._s(activity.title)+" ")],1)}),0),(_vm.selectedDay == 999)?_c('div',{staticClass:"challengeTextMobile"},[_c('vue-markdown',[_vm._v(_vm._s(_vm.content[_vm.selectedChallenge].text))])],1):_c('div',{staticClass:"challengeTextMobile"},_vm._l((_vm.content[_vm.selectedChallenge].activity),function(a,i){return _c('div',{key:i},[(i === _vm.selectedDay)?_c('vue-markdown',[_vm._v(_vm._s(_vm.content[_vm.selectedChallenge].activity[_vm.selectedDay].text)+" ")]):_vm._e()],1)}),0),(_vm.content[_vm.selectedChallenge].author)?_c('div',{staticClass:"author"},[_c('div',{staticClass:"flexRow"},[(_vm.content[_vm.selectedChallenge].author.img)?_c('img',{attrs:{"src":_vm.content[_vm.selectedChallenge].author.img}}):_vm._e(),_c('div',{staticClass:"authorName"},[_vm._v(" Author:"),_c('br'),_vm._v(_vm._s(_vm.content[_vm.selectedChallenge].author.name)+" ")])]),(_vm.content[_vm.selectedChallenge].author.academicDescription)?_c('div',{staticClass:"academicDescription"},[_vm._v(" "+_vm._s(_vm.content[_vm.selectedChallenge].author.academicDescription)+" ")]):_vm._e()]):_vm._e()])]):_vm._e(),_c('md-dialog-actions',[_c('md-button',{staticClass:"md-primary",staticStyle:{"font-size":"1.3em","margin":"0 20px 20px 0"},on:{"click":function($event){(_vm.showChallenge = false),
            (_vm.selectedChallenge = 0),
            (_vm.selectedDay = 999)}}},[_vm._v("Close ")])],1)],1),_c('div',{staticClass:"wrapper"},[(['practice', 'knowledge', 'challenge'].includes(_vm.materialActive))?_c('h1',[_vm._v(_vm._s(_vm.materialActive))]):_c('h1',[_vm._v("Knowledge & Practice")]),_c('hr'),_c('div',{staticClass:"tagBar flexRow"},_vm._l((_vm.materials),function(material){return _c('p',{key:material,class:_vm.materialActive === 'viewall' && material === 'All' ?
            'tagActive' :
            (
              _vm.materialActive === material.toLowerCase() ?
              'tagActive' :
              ''
             ),attrs:{"id":material},on:{"click":function($event){material==='All' ?
            _vm.filterMaterials('viewall') :
            _vm.filterMaterials(material)}}},[_vm._v(" "+_vm._s(material)+" ")])}),0),_c('hr'),_c('div',{staticClass:"tagBar flexRow"},[_vm._l((_vm.tags),function(tag){return _c('p',{key:tag,class:_vm.tagActive === tag ? 'tagActive' : '',attrs:{"id":tag},on:{"click":function($event){return _vm.filterTags(tag)}}},[_vm._v(" "+_vm._s(tag)+" ")])}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"searchInput",attrs:{"type":"text","placeholder":"Search article or author.."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})],2),_c('hr'),_c('div',{staticClass:"articles",attrs:{"id":"articles"}},_vm._l((_vm.filteredArticles),function(article,i){return _c('div',{key:article,class:{ articleMobile: _vm.maxWidth, article: !_vm.maxWidth },attrs:{"id":i}},[(article.content)?_c('div',{on:{"click":function($event){return _vm.$router.push('../../article/' + article._id)}}},[_c('div',{staticClass:"articleImageDiv"},[_c('img',{staticClass:"articleImage",attrs:{"src":article.image}})]),_c('p',{staticClass:"articleTitle"},[_vm._v(_vm._s(article.title))]),_c('p',{staticClass:"articleSubtitle"},[_vm._v(_vm._s(article.subheader))]),(article.author)?_c('p',{staticClass:"articleAuthor"},[_vm._v(_vm._s(article.author.name))]):_vm._e(),_c('p',{staticClass:"articleStars"},[_vm._v("Difficulty - "),_vm._l((article.stars),function(i){return _c('span',{key:i,class:'stars' + article.stars},[_vm._v("⭐")])})],2)]):_c('div',{on:{"click":function($event){(_vm.selectedChallenge = i),
              (_vm.showChallenge = true),
              (_vm.selectedDay = 999)}}},[_c('div',{staticClass:"articleImageDiv"},[_c('img',{staticClass:"articleImage",attrs:{"src":article.image}})]),_c('p',{staticClass:"articleTitle"},[_vm._v(_vm._s(article.title))]),(article.text)?_c('p',{staticClass:"articleSubtitle"},[_vm._v(" "+_vm._s(article.text.split(" ").slice(0, 20).join(" ") + "...")+" ")]):_vm._e(),(article.author)?_c('p',{staticClass:"articleAuthor"},[_vm._v(_vm._s(article.author.name))]):_vm._e()])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }